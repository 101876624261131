header {
  display: flex;
  align-items: center;
  justify-content: center;
}

header > button {
  position: absolute;
  top: 60px;
  display: block;
  outline: none;
  background: none;
  background-color: transparent;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  font-size: 30px;
  font-weight: 500;
  padding: 5px 20px;
}

.navbar {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 10px;
  padding: 5px;
}

.navbar > li {
  display: inline-block;
}

.navbar > li > button {
  display: block;
  outline: none;
  background: none;
  background-color: transparent;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 20px;
}
