.horizontal-line {
    color: #D9D9D9;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0px;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #D9D9D9;
}

.text {
    margin: 0 10px;
}